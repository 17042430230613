import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    active: String,
    searchon: Boolean
  }

  static targets = [
    "advertise",
    "advertiseContainer",
    "bookus",
    "bookusContainer",
    "hideSearchIcon",
    "ondemand",
    "ondemandContainer",
    "searchButton",
    "showSearchIcon",
    "searchContainer",
    "upcoming",
    "upcomingContainer",
    "viewall"
  ]

  connect() {
    this.selectTab()
    this.search()
  }

  selectTab() {
    if (this.activeValue == 'upcoming') {
      this.upcoming()
    } else if (this.activeValue == 'ondemand') {
      this.ondemand()
    } else if (this.activeValue == 'bookus') {
      this.bookus()
    } else if (this.activeValue == 'advertise') {
      this.advertise()
    } else {
      this.upcoming()
    }
  }

  deselect() {
    this.upcomingTarget.classList.remove('text-green-500')
    this.ondemandTarget.classList.remove('text-green-500')
    this.bookusTarget.classList.remove('text-green-500')

    this.upcomingContainerTarget.classList.add('hidden')
    this.ondemandContainerTarget.classList.add('hidden')
    this.bookusContainerTarget.classList.add('hidden')
    this.advertiseContainerTarget.classList.add('hidden')
  }

  upcoming() {
    this.deselect()
    this.upcomingTarget.classList.add('text-green-500')
    this.viewallTarget.classList.remove('hidden')
    this.upcomingContainerTarget.classList.remove('hidden')
    this.searchContainerTarget.classList.remove('hidden')
    this.searchButtonTarget.classList.remove('hidden')
    this.activeValue = 'upcoming'
    $("#tab").val('upcoming')
    this.searchonValue ? this.showSearch() : this.hideSearch()
    window.history.pushState('', '', '/#upcoming_events');
  }

  ondemand() {
    this.deselect()
    this.ondemandTarget.classList.add('text-green-500')
    this.viewallTarget.classList.remove('hidden')
    this.ondemandContainerTarget.classList.remove('hidden')
    this.searchContainerTarget.classList.remove('hidden')
    this.searchButtonTarget.classList.remove('hidden')
    this.activeValue = 'ondemand'
    $("#tab").val('ondemand')
    this.searchonValue ? this.showSearch() : this.hideSearch()
    window.history.pushState('', '', '/#ondemand');
  }

  bookus() {
    this.deselect()
    this.bookusTarget.classList.add('text-green-500')
    this.viewallTarget.classList.add('hidden')
    this.bookusContainerTarget.classList.remove('hidden')
    this.searchContainerTarget.classList.add('hidden')
    this.searchButtonTarget.classList.add('hidden')
    this.activeValue = 'bookus'
    $("#tab").val('bookus')
    window.history.pushState('', '', '/#bookus');
  }

  advertise() {
    this.deselect()
    this.advertiseTarget.classList.add('text-green-500')
    this.viewallTarget.classList.add('hidden')
    this.advertiseContainerTarget.classList.remove('hidden')
    this.searchContainerTarget.classList.add('hidden')
    this.searchButtonTarget.classList.add('hidden')
    this.activeValue = 'advertise'
    $("#tab").val('advertise')
    window.history.pushState('', '', '/#advertise');
  }

  search() {
    this.searchonValue = !this.searchonValue
    console.log('this.searchonValue', this.searchonValue)
    if (this.searchonValue) {
      this.showSearch()
    } else {
      this.hideSearch()
    }
  }

  hideSearch() {
    this.searchContainerTarget.classList.add('hidden')
    this.showSearchIconTarget.classList.remove('hidden')
    this.hideSearchIconTarget.classList.add('hidden')
  }

  showSearch() {
    this.searchContainerTarget.classList.remove('hidden')
    this.showSearchIconTarget.classList.add('hidden')
    this.hideSearchIconTarget.classList.remove('hidden')
  }

}
