import { Controller } from "stimulus"

export default class extends Controller {

  static targets =
    [
      'form',
      'phone',
      'email',
      'phoneMsg',
      'formMsg',
      'marketingSolutions'
    ];

  connect() {
    this.translateDateField();
  }

  translateDateField() {
    let dateF = 'input[type=date]';
    let addTransCls = function(el) {
      if (!$(el).val()) {
        $(el).addClass('text-transparent');
      }
    }

    $(dateF).each(function() {
      addTransCls(this);
    });

    $(dateF).focus(function() {
      $(this).removeClass('text-transparent');
    });

    $(dateF).focusout(function() {
      addTransCls(this);
    });
  }

  submitForm(event) {
    let isValid = this.validateForm(this.formTarget);
    this.formMsgTarget.innerText = "";

    if (!isValid) {
      event.preventDefault();
      return;
    }

    this.formMsgTarget.innerText = "Your message has been sent!";
    this.formMsgTarget.classList.add('text-blue-500');

    let requiredFields = $('#contact_form .form-input');
    requiredFields.each(function() {
      $(this).val('');
    });
    this.translateDateField();
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateForm() {
    let isValid = true;

    let requiredFields = $('#contact_form .form-input');

    requiredFields.each(function() {
      $(this).removeClass('border-red-500');
      if ($(this).data('required') == true && $(this).val() == '') {
        $(this).addClass('border-red-500');
        isValid = false;
      }
    });

    let phoneField = this.formTarget.querySelector('#contact_phone');
    this.phoneMsgTarget.innerText = "";
    if (phoneField.value != '') {
      var phoneno = /^\(?([0-9]{3})\)?-([0-9]{3})-([0-9]{4})$/;
      if(phoneField.value.match(phoneno) == null) {
        this.phoneTarget.classList.add('border-red-500');

        this.phoneMsgTarget.innerText = "Valid phone format: xxx-xxx-xxxx";
        this.phoneMsgTarget.classList.add('text-red-500');

        isValid = false;
      }
    }

    if (!this.validateEmail(this.emailTarget.value)) {
      this.emailTarget.classList.add('border-red-500');
      isValid = false;
    }

    return isValid;
  }
}
