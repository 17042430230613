import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'date', 'search', 'flash'];

  reset() {
    $('#date').val('')
    $('#search').val('')
    this.formTarget.submit()
  }

  submitForm(event) {
    let isValid = this.validateForm(this.formTarget);

    if (!isValid) {
      event.preventDefault();
    }
  }

  validateForm() {
    let isValid = true;
    let self = this;

    if (this.dateTarget.value == '' && this.searchTarget.value == '') {
      isValid = false;
      this.flashTarget.innerText = 'Some input is required.';

      setTimeout(function() {
        self.flashTarget.innerText = '';
      }, 3000);
    }

    return isValid;
  }
}
